@import './variables';

[data-component='SetDisplayName'] {
    position: absolute;
    padding: 2rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba($colorBg1, .8);
    border-radius: 1rem;
    box-shadow: 0 5px 12px 2px rgba(#111, .5);

    > form {
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    
        > h1 {
            color: rgba(white, .75);
            margin: 0;
            margin-bottom: 1rem;
            user-select: none;
            cursor: default;
        }

        label.headphone-mode {
            color: white;
            font-size: 1.25rem;
            user-select: none;

            &.checked {
                color: rgb(178, 228, 178);
            }
        }
        input[type='text'] {
            font-size: 1.25rem;
            background: transparent;
            transition: background 1s;
            transition: background .25s;
            border: none;
            color: rgba(white, .75);
            border-bottom: 1px solid white;
            border-radius: 4px;
            padding: 6px;
            width: 100%;
            margin-bottom: 1rem;

            &:focus {
                outline: none;
            }

            &:hover, &:focus {
                background: rgba(black, .5);
            }

            &.error {
                background: rgba(165, 9, 9, 0.418);
                transition: background 1s;
            }
        }

        button {
            font-size: 1.25rem;
            color: rgba(white, .8);
            border-radius: 4px;
            background-color: #67b26b;
            border: none;
            text-align: center;
            text-transform: uppercase;
            padding: 5px 10px 5px 10px;
            transition: all .4s;
            cursor: pointer;
            margin: 5px;
            white-space: nowrap;
            cursor: pointer;

            &:disabled {
                transition: background .4s;
                background-color: #b08d8d;
                cursor: not-allowed;
            }

            > span {
                display: inline-block;
                position: relative;
                transition: 0.4s;
                height: 1em;

                &:after {
                    content: '\00bb';
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    right: -20px;
                    transition: 0.5s;
                }
            }

            &:not(:disabled) {
                &:hover > span {
                    padding-right: 25px;

                    &:after {
                        opacity: 1;
                        right: 0;
                    }
                }
            }
        }
    }
}
