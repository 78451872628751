$colorBg1: #301d54;
$colorSecondary: #80ff40;

$notificationHeight: 3rem;
$notificationPadding: 12px 24px 12px 12px;
$notificationTransitionType: 300ms ease-in-out 0s;

$peerViewBackgroundTint: black;

$mobileMaxWidth: 640px;

$meWidth: 320px;
$meHeight: 200px;
$meWidthMobile: 160px;
$meHeightMobile: 100px;

$peerMargin: 10px;
$peerMarginMobile: 5px;
