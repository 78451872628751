[data-component='Peer'] {
    flex: 100 100 auto;
    position: relative;
    width: 100%;
    height: 100%;

    > .indicators {
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
        width: 150px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        > .icon {
            flex: 0 0 auto;
            margin: 4px;
            margin-left: 0;
            width: 32px;
            height: 32px;
            background-position: center;
            background-size: 75%;
            background-repeat: no-repeat;
            transition: opacity .15s;

            opacity: .85;

            &.control {
                flex: 0 0 auto;
                margin: 4px;
                margin-left: 0;
                border-radius: 2px;
                pointer-events: auto;
                background-position: center;
                background-size: 75%;
                background-repeat: no-repeat;
                background-color: rgba(black, 0.5);
                cursor: pointer;
                transition: opacity 150ms, background-color 150ms, transform 250ms;
    
                width: 28px;
                height: 28px;
                opacity: 0.85;
            
                &:hover {
                    opacity: 1;
                }
    
                &.unsupported {
                    pointer-events: none;
                }
    
                &.disabled {
                    pointer-events: none;
                    opacity: 0.5;
                }
    
                &.in-progress {
                    animation: Me-button-in-progress 2s infinite ease-in-out;
                }
            }

            &.mic-off {
                background-image: url(../resources/icons/icon_remote_mic_white_off.svg);
            }

            &.mic-p2p {
                background-image: url(../resources/icons/icon_mic_white_p2p.svg);
            }

            &.webcam-enable {
                background-image: url(../resources/icons/icon_webcam_black_on.svg);
                background-color: rgba(white, 0.85);
            }

            &.webcam-disable {
                background-image: url(../resources/icons/icon_remote_webcam_white_off.svg);
                background-color: rgba(black, 0.85);
            }

            &.webcam-off {
                background-image: url(../resources/icons/icon_remote_webcam_white_off.svg);
            }

            &.quality-2-2 {
                background-image: url(../resources/icons/quality-4.png);
            }

            &.quality-2-1 {
                background-image: url(../resources/icons/quality-3.png);
            }

            &.quality-2-0 {
                background-image: url(../resources/icons/quality-2.png);
            }

            &.quality-1-2 {
                background-image: url(../resources/icons/quality-3.png);
            }

            &.quality-1-1 {
                background-image: url(../resources/icons/quality-2.png);
            }

            &.quality-1-0 {
                background-image: url(../resources/icons/quality-1.png);
            }

            &.quality-0-2 {
                background-image: url(../resources/icons/quality-2.png);
            }

            &.quality-0-1 {
                background-image: url(../resources/icons/quality-1.png);
            }

            &.quality-0-0 {
                background-image: url(../resources/icons/quality-1.png);
            }
        }
    }

    .incompatible-video {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        > p {
            padding: 6px 12px;
            border-radius: 6px;
            user-select: none;
            pointer-events: none;
            font-size: 1.25rem;
            color: rgba(white, .55);
        }
    }
}
