[data-component='Me'] {
    position: relative;
    width: 100%;
    height: 100%;

    > .controls {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        pointer-events: none;

        &.has-overlay {
            filter: blur(2px);
        }

        > .button {
            flex: 0 0 auto;
            margin: 4px;
            margin-left: 0;
            border-radius: 2px;
            pointer-events: auto;
            background-position: center;
            background-size: 75%;
            background-repeat: no-repeat;
            background-color: rgba(black, 0.5);
            cursor: pointer;
            transition: opacity 150ms, background-color 150ms, transform 250ms;

            width: 28px;
            height: 28px;
            opacity: 0.85;
            
            &:hover {
                opacity: 1;
            }

            &.unsupported {
                pointer-events: none;
            }

            &.disabled {
                pointer-events: none;
                opacity: 0.5;
            }

            &.in-progress {
                animation: Me-button-in-progress 2s infinite ease-in-out;
            }

            &.on {
                background-color: rgba(white, 0.85);
            }

            &.mic {
                &.on {
                    background-image: url(../resources/icons/icon_mic_black_on.svg);
                }
                &.off {
                    background-image: url(../resources/icons/icon_mic_white_off.svg);
                }
                &.unsupported {
                    background-image: url(../resources/icons/icon_mic_white_unsupported.svg);
                }
            }

            &.webcam {
                &.on {
                    background-image: url(../resources/icons/icon_webcam_black_on.svg);
                }
                &.off {
                    background-image: url(../resources/icons/icon_webcam_white_off.svg);
                }
                &.unsupported {
                    background-image: url(../resources/icons/icon_webcam_white_unsupported.svg);
                }
            }

            &.webcam-mirror {
                background-image: url(../resources/icons/icon_mirror.png);
            }

            &.change-webcam {
                &.on {
                    background-image: url(../resources/icons/icon_change_webcam_black.svg);
                }
                &.unsupported {
                    background-image: url(../resources/icons/icon_change_webcam_white_unsupported.svg);
                }
            }
        }
    }

    > .choose {
        position: absolute;
        z-index: 11;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: stretch;
        background: rgba(black, 0.25);
    }

    > .peer-view {
        width: 100%;
        height: 100%;

        &.has-overlay {
            filter: blur(2px);
        }
    }
}

@keyframes Me-button-in-progress {
    0% {
        opacity: 1;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}