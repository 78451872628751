@import "./variables";

[data-component="Notifications"] {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    text-align: right;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1000;

    .notification {
        height: $notificationHeight;
        margin-top: 4px;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.2rem;
        padding: $notificationPadding;

        .icon {
            flex: 0 0 auto;
            width: 24px;
            height: 24px;
            margin-right: 12px;
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
        }

        .text {
            user-select: none;
            cursor: default;
            line-height: 1.35em;
        }

        &.info {
            background-color: darken($colorBg1, 10%);
            border: 2px solid lighten($colorBg1, 10%);
            color: rgba(#fff, 0.75);

            > .icon {
                background-image: url(../resources/icons/icon_notification_info_white.svg);
           }
        }

        &.warning {
            $warningColor: #ffb114;
            background-color: darken($warningColor, 10%);
            border: 2px solid lighten($warningColor, 10%);
            color: rgba(#fff, 0.75);

            > .icon {
                background-image: url(../resources/icons/icon_notification_info_white.svg);
            }
        }

        &.error {
            $errorColor: #ff1914;
            background-color: rgba(darken($errorColor, 10%), 0.75);
            border-color: 2px solid lighten($errorColor, 10%);
            color: #fff;

            > .icon {
                background-image: url(../resources/icons/icon_notification_error_white.svg);
            }
        }
    }

    .notification-appear {
        opacity: 0;
        transform: scale(0.9);
        margin-right: -100%;
        height: 0;
        padding: 0;
    }

    .notification-appear-active {
        opacity: 1;
        transform: scale(1);
        margin-right: 0;
        height: $notificationHeight;
        padding: $notificationPadding;
        transition: opacity $notificationTransitionType, transform $notificationTransitionType,
            margin-right $notificationTransitionType, height $notificationTransitionType,
            padding $notificationTransitionType;
    }

    .notification-exit {
        opacity: 1;
        height: $notificationHeight;
        margin-right: 0;
    }

    .notification-exit-active {
        opacity: 0;
        margin-right: -50%;
        height: 0;
        padding: 0;
        transition: opacity $notificationTransitionType, transform $notificationTransitionType,
            height $notificationTransitionType, padding $notificationTransitionType, margin-right $notificationTransitionType;
    }
}
