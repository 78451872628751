@import "./variables";

$maxAspectRatio: 16/10;

[data-component="Peers"] {
    position: absolute;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;

    @media (min-width: $mobileMaxWidth + 1) {
        gap: $peerMargin;
        left: $peerMargin;
        top: $peerMargin;
        width: $meWidth;
        height: calc(100vh - #{$meHeight + 3 * $peerMargin});

        @media (max-aspect-ratio: $maxAspectRatio) {
            left: $meWidth + 2 * $peerMargin;
            top: calc(100vh - #{$meHeight + $peerMargin});
            bottom: $peerMargin;
            width: calc(100vw - #{$meWidth + 3 * $peerMargin});
            height: $meHeight;
            flex-direction: row;
        }
    }

    @media (max-width: $mobileMaxWidth) {
        gap: 10px;
        left: $peerMarginMobile;
        top: $peerMarginMobile;
        bottom: $peerMarginMobile + $meHeightMobile;
        width: $meWidthMobile;
        height: calc(100vh - #{$meHeightMobile + 3 * $peerMarginMobile});
        flex-direction: row;

        @media (max-aspect-ratio: $maxAspectRatio) {
            left: $meWidthMobile + 2 * $peerMarginMobile;
            top: calc(100vh - #{$meHeightMobile + $peerMarginMobile});
            bottom: $peerMarginMobile;
            height: $meHeightMobile;
            width: calc(100vw - #{$meWidthMobile + 3 * $peerMarginMobile});
            flex-direction: column;
        }
    }

    > .peer-container {
        overflow: hidden;
        flex: 1;
        max-width: 320px;
        max-height: 200px;
        border: 1px solid rgba(white, 0.15);
        box-shadow: 0 5px 12px 2px rgba(#111, 0.5);
        transition: border-color 0.25s, opacity 0.25s; // For fade in. See index.scss.

        @media (max-width: $mobileMaxWidth) {
            max-width: 160px;
            max-height: 100px;
        }

        &.active-speaker {
            border-color: white;
        }

        &:not(.active-peer) {
            cursor: pointer;
        }

        &.active-peer {
            position: fixed;
            max-width: initial;
            max-height: initial;

            @media (min-width: $mobileMaxWidth + 1) {
                left: calc(320px + 20px);
                top: $peerMargin;
                width: calc(100vw - 350px);
                height: calc(100vh - 20px);
                @media (max-aspect-ratio: $maxAspectRatio) {
                    left: $peerMargin;
                    top: $peerMargin;
                    width: calc(100vw - #{2 * $peerMargin});
                    height: calc(100vh - #{$meHeight + 3 * $peerMargin});
                }
            }
            @media (max-width: $mobileMaxWidth) {
                left: $meWidthMobile + 2 * $peerMarginMobile;
                top: $peerMarginMobile;
                width: calc(100vw - #{$meWidthMobile + 3 * $peerMarginMobile});
                height: calc(100vh - #{2 * $peerMarginMobile});

                @media (max-aspect-ratio: $maxAspectRatio) {
                    left: $peerMarginMobile;
                    top: $peerMarginMobile;
                    width: calc(100vw - #{2 * $peerMarginMobile});
                    height: calc(100vh - #{$meHeightMobile + 3 * $peerMarginMobile});
                }
            }
        }
    }

    > .wait-for-peers {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        pointer-events: none;
        background-color: $colorBg1;
        opacity: 0.75;

        > span {
            font-size: 2rem;
            text-shadow: 2px 2px 4px darken($colorBg1, 50%);
            text-align: center;
            color: rgba(white, 0.85);
        }
    }
}

@keyframes Peers-wait-for-peers {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
