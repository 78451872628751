@import './variables';

[data-component='ChooseDevice'] {
    position: relative;
    flex: 1;
    width: calc(100% - 2rem);
    margin: 1rem;
    margin-top: 2.1rem;
    color: rgba(white, .75);

    > .cancel {
        position: absolute;
        right: 0;
        top: -1.25rem;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: white;
        background-image: url(../resources/icons/icon_close_black.svg);
        border-color: 2px solid #e8e6e3;
        cursor: pointer;
        user-select: none;

        &:hover {
            background-color: $colorSecondary;
        }
    }

    > .header {
        font-weight: bold;
        font-size: 1.1rem;
        cursor: default;
        pointer-events: none;
        user-select: none;
    }

    > ul {
        list-style: none;
        margin: 0;
        margin-left: 1rem;
        padding: 0;
        width: calc(100% - 1rem);

        > li {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            user-select: none;

            &:hover {
                color: $colorSecondary;
            }

            &:before {
                display: inline-block;
                content: '';
                line-height: 1rem;
                width: 16px;
                height: 1rem;
                margin-bottom: -3px;
                vertical-align: baseline;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }

            &.mic-disable:before {
                background-image: url(../resources/icons/icon_mic_white_off.svg);
            }

            &.mic:before {
                background-image: url(../resources/icons/icon_mic_white_on.svg);
                opacity: .25;
            }
            
            &.mic-active:before {
                background-image: url(../resources/icons/icon_mic_white_on.svg);
                opacity: 1;
            }


            &.webcam-disable:before {
                background-image: url(../resources/icons/icon_remote_webcam_white_off.svg);
            }

            &.webcam:before {
                background-image: url(../resources/icons/icon_webcam_white_on.svg);
                opacity: .25;
            }
            
            &.webcam-active:before {
                background-image: url(../resources/icons/icon_webcam_white_on.svg);
                opacity: 1;
            }
        }
    }
}
