@font-face {
    font-family: vollkorn;
    src: url(../resources/fonts/Vollkorn-Regular.woff);
    font-display: swap;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    background-image: url(../resources/images/body-bg-4.jpg);
    background-size: cover;

    font-family: vollkorn, sans-serif;
    font-size: 16px;
}

@media (prefers-color-scheme: dark) {
    body {
        background-image: url(../resources/images/body-bg-4-dark.jpg);
    }
}

html * {
    box-sizing: border-box;
}

.fade-in-appear {
    opacity: 0;
}

.fade-in-appear-active {
    opacity: 1;
    transition: opacity .25s;
}

.fade-in-appear-done {
    opacity: 1;
}
