@import "./variables";

[data-component="Room"] {
    position: relative;
    width: 100%;
    height: 100%;

    > .state {
        position: fixed;
        z-index: 100;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-bottom-right-radius: 4px;
        background-color: lighten($colorBg1, 10%);
        height: 2rem;
        opacity: 1;
        transition-duration: .25s;
        transition-property: opacity, font-size, height;

        left: 0;
        top: 0;

        &.connected {
            opacity: .5;
            height: 1rem;
            font-size: .75rem;

            > .text {
                display: none;
            }
        }

        &.disconnected,
        &.closed {
            background-color: rgb(95, 32, 32);
        }

        > .icon {
            flex: 0 0 auto;
            border-radius: 100%;
    
            margin: 6px;
            width: 16px;
            height: 16px;

            transition-duration: .25s;
            transition-property: width, height;

            opacity: 1;
    
            &.new,
            &.closed {
                background-color: rgba(#aaa, 0.5);
            }
        
            &.connecting {
                animation: Room-info-state-connecting 1s infinite linear;
            }
        
            &.awaiting-media {
                background-image: url(../resources/icons/icon_mic_white_on.svg);
                background-size: contain;
                animation: Room-info-state-awaiting-media 2.5s infinite ease-in-out;
            }
        
            &.connected {
                background-color: rgba(#2aa215, 0.75);
                width: 10px;
                height: 10px;
                margin: 4px;
            }
        }
    
        > .text {
            flex: 100 0 auto;
            padding-right: 6px;
            user-select: none;
            pointer-events: none;
            text-align: center;
            text-transform: uppercase;
            font-weight: 400;
            color: rgba(white, 0.75);

            transition-duration: .25s;
            transition-property: width, padding;
        }
    }

    > .room-link-wrapper {
        pointer-events: none;
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        right: 0;
        // display: flex;
        display: none;
        flex-direction: row;
        justify-content: center;
        display: none;

        > .room-link {
            width: auto;
            background-color: lighten($colorBg1, 10%);
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            box-shadow: 0 3px 12px 2px rgba(#111, .4);

            > a.link {
                display: block;
                user-select: none;
                pointer-events: none;
                font-weight: 400;
                color: rgba(white, .75);
                cursor: pointer;
                text-decoration: none;
                transition: color .1s;

                &:hover {
                    color: white;
                    text-decoration: underline;
                }
            }
        }
    }

    > .me-container {
        position: fixed;
        z-index: 100;
        overflow: hidden;
        box-shadow: 0 5px 12px 2px rgba(#111, .5);
        transition: border-color .2s;
        border: 1px solid rgba(white, .15);

        width: $meWidth;
        height: $meHeight;
        bottom: $peerMargin;
        left: $peerMargin;

        @media (max-width: $mobileMaxWidth) {
            width: $meWidthMobile;
            height: $meHeightMobile;
            bottom: $peerMarginMobile;
            left: $peerMarginMobile;
        }

        &.active-speaker {
            border-color: white;
        }
    }
}

@keyframes Room-info-state-connecting {
    50% {
        background-color: rgba(orange, .75);
    }
}

@keyframes Room-info-state-awaiting-media {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1);
    }
}
