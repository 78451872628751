@import "./variables";

div[data-component="PeerView"] {
    position: relative;
    flex: 100 100 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: $colorBg1;
    background-position: bottom;
    background-size: auto 80%;
    background-repeat: no-repeat;

    &.video-hidden {
        background-image: url(../resources/images/buddy.svg);
    }

    > .info {
        position: absolute;
        pointer-events: none;
        z-index: 5;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: linear-gradient(
            to bottom
            rgba($peerViewBackgroundTint, 0) 0%,
            rgba($peerViewBackgroundTint, 0) 70%,
            rgba($peerViewBackgroundTint, .1) 80%,
            rgba($peerViewBackgroundTint, .5) 100%,
        );

        > .peer {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            &.is-me {
                padding: 10px;
                align-items: flex-start;
            }

            &:not(.is-me) {
                padding: 20px;
                align-items: flex-start;
                pointer-events: none;
            }

            > .display-name {
                font-size: 1.25rem;
                font-weight: 400;
                color: rgba(white, 0.85);
            }

            > span.display-name {
                user-select: none;
                cursor: text;

                &:not(.editable) {
                    cursor: default;
                }

                &.editable {
                    pointer-events: auto;
                    &:hover {
                        background-color: $colorSecondary;
                    }
                }

                &.loading {
                    opacity: 0.5;
                }
            }

            > input.display-name {
                border: none;
                border-bottom: 1px solid $colorSecondary;
                background-color: transparent;
            }
        }
    }

    > video {
        flex: 100 100 auto;
        width: 100%;
        height: 100%;
        object-fit: contain;
        user-select: none;
        transition: opacity 0.5s;
        background-color: rgba(black, 0.75);

        &.video-mirrored {
            transform: scaleX(-1);
        }

        &.hidden {
            opacity: 0;
        }

        &.network-error {
            filter: grayscale(100%) brightness(135%) blur(5px);
        }
    }

    > audio {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        border: 2px solid red;
    }

    > .volume-container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 10px;
        pointer-events: none;
        z-index: 10;

        > .bar {
            height: 6px;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;

            &.level-0 {
                width: 0%;
                background-color: rgba(lightgreen, 0.65);
            }
            &.level-1 {
                width: 1%;
                background-color: rgba(lightgreen, 0.65);
            }
            &.level-2 {
                width: 2%;
                background-color: rgba(lightgreen, 0.65);
            }
            &.level-3 {
                width: 4%;
                background-color: rgba(green, 0.65);
            }
            &.level-4 {
                width: 6%;
                background-color: rgba(green, 0.65);
            }
            &.level-5 {
                width: 8%;
                background-color: rgba(green, 0.65);
            }
            &.level-6 {
                width: 13%;
                background-color: rgba(green, 0.65);
            }
            &.level-7 {
                width: 17%;
                background-color: rgba(green, 0.65);
            }
            &.level-8 {
                width: 22%;
                background-color: rgba(green, 0.65);
            }
            &.level-9 {
                width: 28%;
                background-color: rgba(yellow, 0.65);
            }
            &.level-10 {
                width: 35%;
                background-color: rgba(yellow, 0.65);
            }
            &.level-11 {
                width: 44%;
                background-color: rgba(yellow, 0.65);
            }
            &.level-12 {
                width: 48%;
                background-color: rgba(yellow, 0.65);
            }
            &.level-13 {
                width: 52%;
                background-color: rgba(orange, 0.65);
            }
            &.level-14 {
                width: 56%;
                background-color: rgba(orange, 0.65);
            }
            &.level-15 {
                width: 60%;
                background-color: rgba(orange, 0.65);
            }
            &.level-16 {
                width: 64%;
                background-color: rgba(orange, 0.65);
            }
            &.level-17 {
                width: 68%;
                background-color: rgba(orange, 0.65);
            }
            &.level-18 {
                width: 72%;
                background-color: rgba(orange, 0.65);
            }
            &.level-19 {
                width: 76%;
                background-color: rgba(red, 0.65);
            }
            &.level-20 {
                width: 80%;
                background-color: rgba(red, 0.65);
            }
            &.level-21 {
                width: 84%;
                background-color: rgba(red, 0.65);
            }
            &.level-22 {
                width: 88%;
                background-color: rgba(red, 0.65);
            }
            &.level-23 {
                width: 92%;
                background-color: rgba(darkred, 0.65);
            }
            &.level-24 {
                width: 96%;
                background-color: rgba(darkred, 0.65);
            }
        }
    }

    > .spinner-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        background-color: rgba(black, 0.75);

        .react-spinner {
            position: relative;
            width: 48px;
            height: 48px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .react-spinner_bar {
                position: absolute;
                width: 50%;
                height: 7.8%;
                top: -3.9%;
                left: -10%;
                animation: PeerView-spinner 1.2s linear infinite;
                background-color: rgba(black, 0.25);
            }
        }
    }

    > .video-elem-paused {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        background-position: center;
        background-image: url(../resources/icons/icon_video_elem_paused.svg);
        background-size: 128px;
        background-color: rgba(black, 0.25);
        background-repeat: no-repeat;
        opacity: 1;
        transition: opacity .25s;

        &.hidden {
            opacity: 0;
        }
    }
}

@keyframes PeerView-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.15;
    }
}
